<template>
  <div>
    <result-values />
  </div>
</template>

<script>
import ResultValues from '@/components/catalog/ResultValues/ResultValues.vue'

export default {
  components: { ResultValues },
  name: 'ResultValue',
}
</script>
