<template>
  <div>
    <v-dialog
      v-model="isActive"
      persistent
      transition="dialog-transition"
      width="325"
      height="180"
    >
      <span class="d-none">{{ change }}</span>

      <v-card class="rounded-lg">
        <v-card-title>
          <v-row>
            <v-col cols="11" class="px-0">
              <h6 class="text-subtitle-1 font-weight-bold text-center">
                <span class="font-work-sans ml-9"
                  >{{ edit ? 'Modificar ' : 'Crear ' }}Valor del resultado</span
                >
              </h6>
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-btn color="primary" icon @click="$emit('dismiss')"
                ><v-icon small>fa-times</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <label class="ml-4 black--text font-weight-medium"
              >Resultados</label
            >
            <v-select
              ref="info"
              placeholder="Resultados"
              :rules="[rules.required]"
              v-model="resultValueData.testResultId"
              prepend-inner-icon="fa-file-alt"
              :items="results"
              outlined
              rounded
              class="mt-2 mb-n3"
            ></v-select>

            <label class="ml-4 black--text font-weight-medium">Detalle</label>
            <v-select
              ref="info"
              placeholder="Detalle"
              :rules="[rules.required]"
              v-model="resultValueData.testDetailId"
              prepend-inner-icon="fa-file-signature"
              :items="details"
              outlined
              rounded
              class="mt-2 mb-n3"
            ></v-select>

            <label class="ml-4 black--text font-weight-medium">Valor</label>
            <v-text-field
              placeholder="Valor"
              color="primary"
              rounded
              prepend-inner-icon="fa-file-contract"
              :rules="[rules.required, rules.alfanumeric]"
              required
              outlined
              class="mt-2 mb-n3"
              v-model="resultValueData.value"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions class="mt-n4">
          <v-btn
            color="primary"
            outlined
            class="rounded-lg px-4"
            @click="
              () => {
                $refs.form.reset()
                $emit('dismiss')
              }
            "
          >
            <v-icon small>fa-times</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Cancelar</span>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            color="success"
            class="rounded-lg elevation-0 px-4"
            @click="submit"
            :loading="loading"
          >
            <v-icon small>fa-check</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Aceptar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-edit
      :item="item"
      :dialog="dialogEdit"
      @dismiss="
        () => {
          dialogEdit = false
        }
      "
      @accept="activeAlerts()"
    />
  </div>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory'
import Swal from 'sweetalert2'
import DialogEdit from '@/components/DialogEdit.vue'
const ResultValueRepository = Repository.get('resultValues')
const ResultRepository = Repository.get('results')
const DetailRepository = Repository.get('details')

export default {
  components: { DialogEdit },
  name: 'FormDialogResultValue',
  props: {
    resultValue: {
      type: Object,
      default: {
        testResultId: '',
        testDetailId: '',

        value: '',
      },
    },
    dialog: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
  },
  data: () => ({
    isActive: false,
    valid: false,
    loading: false,
    resultValueData: {
      testResultId: '',
      testDetailId: '',
      value: '',
    },
    dialogEdit: false,
    item: {},
    results: [],
    details: [],
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      alfanumeric: (value) => {
        const pattern = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g
        return !pattern.test(value) || 'Campo inválido.'
      },
    },
  }),
  computed: {
    change: async function () {
      try {
        this.isActive = await this.dialog
        if (this.resultValue)
          this.resultValueData = Object.assign({}, this.resultValue)
      } catch (error) {
        console.log(error)
      }
    },
  },
  mounted: async function () {
    await this.getResults()
    await this.getDetails()
  },
  methods: {
    getResults: async function () {
      try {
        const res = (await ResultRepository.get()).data.data
        this.results = Array.from(res.items, (item) => {
          return {
            text: item.id,
            value: item.id,
          }
        })
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.message : error

        console.log(response)
      }
    },
    getDetails: async function () {
      try {
        const res = (await DetailRepository.get()).data.data
        this.details = Array.from(res.items, (item) => {
          return {
            text: item.name || item.id,
            value: item.id,
          }
        })
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.message : error

        console.log(response)
      }
    },

    submit: function () {
      this.$refs.form.validate()

      setTimeout(() => {
        if (this.valid) {
          if (!this.edit) this.activeAlerts()
          else {
            this.item = {
              type: 'valor del resultado',
              id: this.resultValue.id,
              name: this.resultValue.testDetail.name,
            }
            this.dialogEdit = true
          }
        }
      })
    },

    activeAlerts: async function () {
      this.dialogEdit = false

      const data = {
        type: 'success',
        title: 'Petición exitosa',
        text: this.edit
          ? 'Valor del resultado actualizado con éxito'
          : 'Valor del resultado registrado con éxito',
      }

      this.loading = true

      try {
        this.edit
          ? await ResultValueRepository.update(
              this.resultValueData,
              this.resultValueData.id
            )
          : await ResultValueRepository.create(this.resultValueData)

        this.$store.dispatch('alerts/activeAlerts', data)
        this.$refs.form.reset()
        this.$emit('dismiss')
      } catch (error) {
        const response = error.response
          ? error.response.data.message ||
            error.response.data.errors?.reduce((acc, cur) => ({
              msg: acc.msg + ', ' + cur.msg,
            })).msg
          : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }

      this.loading = false
    },
  },
}
</script>
