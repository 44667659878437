var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-5 mx-md-16 px-md-16"},[_c('v-data-table',{staticClass:"elevation-1 rounded-lg",attrs:{"headers":_vm.headers,"items":_vm.resultValues.items,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editResultValues(item)}}},[_c('v-icon',{attrs:{"small":"","color":"orange"}},[_vm._v("fa-edit")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteResultValues(item)}}},[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("fa-trash")])],1)]}}])}),_c('div',{staticClass:"pt-2 d-flex flex-column flex-md-row align-center justify-end"},[_c('span',{staticClass:"font-quicksand"},[_vm._v("Filas por páginas: ")]),_c('span',{staticClass:"mx-md-2",staticStyle:{"width":"50px"}},[_c('v-select',{staticClass:"font-quicksand",attrs:{"items":_vm.itemsPerPages},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('span',{staticClass:"font-quicksand"},[_vm._v("1-"+_vm._s(_vm.itemsPerPage)+" de "+_vm._s(_vm.resultValues.totalItems)+" ")]),_c('v-pagination',{staticClass:"font-work-sans",attrs:{"length":_vm.pageCount,"total-visible":_vm.pageVisible,"color":"red"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('resultValue-dialog',{attrs:{"resultValue":_vm.resultValue,"del":_vm.del,"dialog":_vm.dialog},on:{"dismiss":function () {
        _vm.dialog = false
        _vm.loadData()
      }}}),_c('form-dialog',{attrs:{"resultValue":_vm.resultValue,"edit":_vm.edit,"dialog":_vm.dialog2},on:{"dismiss":function () {
        _vm.dialog2 = false
        _vm.loadData()
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }